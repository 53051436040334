<template>
  <div style="background-color: white">
    <div class="header p_sticky">
      <div class="wrap">
        <div class="title flex-row flex-center">
          <router-link to="/" class="home">
            <img :src="logo" class="logo" />
            <span class="title-text">
              中医临床药学服务<br />与调剂教育平台
            </span>
          </router-link>
          <ul class="nav-menu flex-row justify-evenly flex1">
            <li
              v-for="(item, index) in links"
              :key="index"
              :class="{ active: item.active }"
            >
              <router-link
                :to="item.url"
                :class="{ active: item.url === '/courses' }"
                >{{ item.name }}</router-link
              >
            </li>
          </ul>
          
            <Avatar :ref="(el) => avatarRef = el" style="margin-right: 37px;" />
          
        </div>
      </div>
    </div>
    <div style="padding: 1px 0">
      <div class="wrap">
        <div class="videos">
          <ul class="flex-row categories flex-wrap">
            <li
              v-for="(item, index) in categories"
              :class="{ active: index === category_index, item: true }"
              :key="index"
              v-text="item.name"
              @click="selectcategory"
              :data-index="index"
            ></li>
          </ul>
          <ul class="flex-row orders">
            <li
              v-for="(item, index) in orders"
              :class="{ active: index === order_index, item: true }"
              :key="index"
              v-text="item.name"
              @click="selectorder"
              :data-index="index"
            ></li>
            <li class="text-right" style="flex: 1">共{{ page.total }}个内容</li>
          </ul>
          <div
            class="flex-row flex-wrap"
            :style="{ minHeight: page.list.length ? '270px' : '540px' }"
          >
            <router-link
              v-for="(item, index) in page.list"
              :to="'/course/' + item.id"
              :key="index"
              class="course"
            >
              <img :src="item.cover" />
              <h3 class="ellipsis" v-text="item.courseName"></h3>
              <div class="flex-row info">
                <div class="flex1">
                  <span class="picon pi-user"></span>{{ item.teacherName }}
                </div>
                <div class="flex1">
                  <span class="picon pi-view"></span>{{ item.numberOfView }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <Pagination :page="page" :topage="topage" />
      </div>
    </div>
  </div>
</template>

<script >
import { Options, Vue } from "vue-class-component";
import "swiper/css/swiper.min.css";
import { get, getLogindata, post } from "@/utils/common";
import { login } from "@/components/Login";
import router from "@/router";
const logo = require("../assets/logo.jpg");

@Options({
  components: {},
})
export default class Home extends Vue {
  avatarRef = "";
  logo = logo;
  categories = [
    {
      id: null,
      name: "全部",
    },
  ];

  category_index = 0;

  orders = [
    {
      id: 1,
      name: "最热",
    },
    {
      id: 2,
      name: "最新",
    },
  ];

  order_index = 0;

  courses = [];

  mounted() {
    this.load();
    let _this = this;
    setTimeout(function () {
      let isLogin = getLogindata() || {};
      if(!isLogin.headImg){
        console.log('aaaaaaa');
        login((logon) => {
          if (logon) {
             _this.avatarRef.LoginStatus(false);
            _this.load();
          }
        });
        return;
      }
    }, 1000);

    
  }

  selectcategory(e) {
    this.category_index = Number(e.currentTarget.dataset.index);
    this.page.current = 1;
    this.loadpage();
  }

  selectorder(e) {
    this.order_index = Number(e.currentTarget.dataset.index);
    this.page.current = 1;
    this.loadpage();
  }

  load() {
    // web_platform/getCommonCoursePart  课程栏目
    get(`web_platform/getCommonCoursePart`, (data) => {
      this.categories = [
        {
          id: null,
          name: "全部",
        },
        ...(data.data || []),
      ];
      this.loadpage();
    });
  }

  topage(e) {
    const to = Number(e.currentTarget.dataset.page);
    if (to !== this.page.current) {
      this.page.current = to;
      this.loadpage();
    }
  }

  size = 8;

  page = {
    total: 0,
    list: [],
    totalPage: 0,
    current: 1,
  };

  loadpage() {
    // web_platform/listPlatformCourse  课程列表
    post(
      `web_platform/listPlatformCourse`,
      {
        page: { from: (this.page.current - 1) * this.size, size: this.size },
        params: {
          sortType: this.orders[this.order_index].id,
          partId: this.categories[this.category_index].id,
        },
      },
      (data, res) => {
        const page = data.data || {};
        page.total =
          this.page.current === 1 ? page.total || 0 : this.page.total;
        page.list = page.list
          ? page.list.map((item) => {
              item.cover = item.imgUrl ? JSON.parse(item.imgUrl)[0] : "";
              return item;
            })
          : [];
        page.totalPage = Math.ceil(page.total / this.size);
        page.current = this.page.current;
        page.start = Math.max(page.current - 2, 1);
        page.end = Math.min(page.start + 4, page.totalPage);
        if (page.end - 4 < page.start) {
          page.start = Math.max(page.end - 4, 1);
        }
        this.page = page;
      }
    );
  }
}
</script>

<style scoped lang="less">
.videos {
  margin: 2rem 0;

  .categories,
  .orders {
    text-align: center;
    li {
      padding: 1em 1rem;
      min-width: 4rem;
    }
    li.active {
      color: #f39801;
    }
    .item {
      cursor: pointer;
    }
  }

  .orders {
    background-color: #f9f9f9;
    font-size: 0.8rem;
  }

  .course {
    width: 23.8%;
    margin: 1.5% 0.6%;
    line-height: 1.8;
    img {
      width: 100%;
      height: 175px;
      object-fit: cover;
      display: block;
      border: 1px solid #aaa;
    }
    .info {
      color: #999;
      font-size: 0.8rem;
    }
    h3 {
      font-weight: 600;
    }
  }
}
.page {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  .pageitem {
    box-sizing: border-box;
    height: 2.4em;
    min-width: 2.4em;
    padding: 0.6em;
    line-height: 1;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    text-align: center;
    margin: 0.25em;
    cursor: default;
  }
  .pageitem.active {
    border-color: #00a0ea;
  }
}
</style>